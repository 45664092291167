import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { GlobalState } from 'store';

import { IDashboardData } from 'models/dashboard';
import { getDashboardData } from 'api/dashboard';

export type DashboardState = {
    isFetching: boolean;
    fetchedAt: string;
    data?: IDashboardData;
};

const initialDashboardState: DashboardState = {
    isFetching: false,
    fetchedAt: '',
};

//#region thunks
export const fetchDashboardDataAsync = createAsyncThunk(
    'dashboard/fetch',
    async () => {
        return await getDashboardData();
    },
    {
        condition: (arg: void, { getState }) => {
            const { dashboard } = getState() as GlobalState;
            if (dashboard.isFetching) {
                return false;
            }
        },
    },
);
//#endregion thunks

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialDashboardState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardDataAsync.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchDashboardDataAsync.fulfilled, (state, action) => {
                state.isFetching = false;
                state.data = action.payload;
                state.fetchedAt = new Date().toJSON();
            })
            .addCase(fetchDashboardDataAsync.rejected, (state) => {
                state.isFetching = false;
                state.fetchedAt = '';
            });
    },
});

//#region selectors
export const selectData = (state: GlobalState) => state.dashboard.data;
export const selectClient = (state: GlobalState) => state.dashboard.data?.client;
export const selectOrg = (state: GlobalState) => state.dashboard.data?.org;
export const selectPayArcRates = (state: GlobalState) => state.dashboard.data?.org?.payArcRates;
export const isFetching = (state: GlobalState) => state.dashboard.isFetching;
export const hasData = (state: GlobalState) => typeof state.dashboard.data === 'object';
//#endregion selctors

export default dashboardSlice.reducer;
