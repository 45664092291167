import React from 'react';
import currency from 'currency.js';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { AiOutlineCalendar, AiFillDollarCircle, AiOutlineInfoCircle, AiOutlineBank, AiFillAlert } from 'react-icons/ai';
import { TbReceiptTax } from "react-icons/tb";

import { AppDispatch, GlobalState } from 'store';
import { fetchDashboardDataAsync } from 'store/slices/dashboard';

import { PageBody } from 'layout/components/pageBody';
import { SimpleDate } from 'utils/dates';
import { isZero, SimpleCurrency } from 'utils/numbers';

import { displayErrorNotification } from 'utils/errors';
import { requestEmailVerification } from 'api/entity';

const mapState = (state: GlobalState) => ({
    data: state.dashboard.data,
    isLoading: state.dashboard.isFetching,
});

const connector = connect(mapState);

interface IDashboardProps extends RouteComponentProps, ConnectedProps<typeof connector>, WithTranslation {}

interface IDashboardState {
    sendingVerify: boolean;
}

class DashboardBase extends React.PureComponent<IDashboardProps, IDashboardState> {
    state: Readonly<IDashboardState> = {
        sendingVerify: false,
    };

    componentDidMount() {
        if (this.props.data) {
            return;
        }

        (this.props.dispatch as AppDispatch)(fetchDashboardDataAsync());
    }

    onViewPaymentMethods = () => {
        this.props.history.push('payment-methods');
    }

    onVerifyEmailClick = () => {
        this.setState({ sendingVerify: true }, async () => {
            try {
                await requestEmailVerification();
                console.log('Email verification requested');
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                this.setState({ sendingVerify: false });
                (this.props.dispatch as AppDispatch)(fetchDashboardDataAsync());
            }
        });
    };

    get updatedAccountInformation() {
        if (!this.props.data || !this.props.data.client || !this.props.data.client.canUpdateInformation) {
            return null;
        }

        if (!this.props.data.client.entity?.hasUpdatePending) {
            return null;
        }

        return (
            <Alert variant="warning">
                <Alert.Heading>Update Pending</Alert.Heading>
                <p>Thank you for submitting your updated account information! We now wait for { this.props.data.org.name } to review it and applying the changes.</p>
                <p>Please contact them if you still see this alert after a few business days.</p>
            </Alert>
        );
    }

    get verifyEmailAlert() {
        if (!this.props.data || !this.props.data.client || !this.props.data.client.entity || this.props.data.client.entity.emailVerified) {
            return null;
        }

        // if they have data update pending, don't show the email verification alert
        if (this.props.data.client.entity?.hasUpdatePending) {
            return null;
        }

        if (this.props.data.client.entity?.emailVerificationPending) {
            return (
                <PageBody.Feature>
                    <div className="col col-sm-12">
                        <Alert variant="info">
                            <Alert.Heading>Email Verification Pending</Alert.Heading>
                            <p>We have sent an email to <strong>{ this.props.data.client.entity.email }</strong> with a link to verify it.</p>
                            <p>Please check your inbox and follow the instructions in the email.</p>
                        </Alert>
                    </div>
                </PageBody.Feature>
            );
        }

        return (
            <Alert variant="warning">
                <Alert.Heading>Email Verification</Alert.Heading>
                <p>{ this.props.t('Lendiom Pay can now send emails for reminders and status updates. Please verify your email to get started using emails.') }</p>
                <div className="d-flex justify-content-end mb-0">
                    <Button size="sm" variant="warning" disabled={this.state.sendingVerify} onClick={this.onVerifyEmailClick}>Send verification now</Button>
                </div>
            </Alert>
        );
    }

    get anyDownPaymentRequiredAlert() {
        if (!this.props.data || !this.props.data.loans) {
            return null;
        }

        const hasDownPayment = this.props.data.loans.some((l) => l.onlinePayment && l.onlinePayment.downPayment && !l.onlinePayment.downPayment.paid);
        if (!hasDownPayment) {
            return null;
        }

        return (
            <Alert variant="warning">
                <Alert.Heading>Down Payment Due</Alert.Heading>
                <p>{ this.props.t('You have at least one down payment due. Please make sure to pay them.') }</p>
            </Alert>
        );
    }

    get loans() {
        if (!this.props.data) {
            return null;
        }

        return (
            <PageBody.List
                title={this.props.t('Loans')}
                emptyContent={<Alert variant="info">{ this.props.t('You currently have no loans.') }</Alert>}
                items={this.props.data.loans.map((l) => (
                    <PageBody.List.Item
                        key={l.id}
                        linksTo={`/loan/${ l.id }`}
                        thumb={<AiFillDollarCircle color={ l.status === 'late' ? '#ff5a5a' : '' } />}
                        title={l.label}
                        description={
                            <React.Fragment>
                                <span className="capitalize">Status: { l.status === 'active' ? 'Current' : l.status.replaceAll('-', ' ') }</span>
                                { l.onlinePayment && l.onlinePayment.downPayment && !l.onlinePayment.downPayment.paid ?
                                    <React.Fragment>
                                        &nbsp;&mdash;
                                        <AiFillAlert color="#ff0000" style={{ marginTop: '-7px', fontSize: '18px' }} />
                                        <span className="capitalize">&nbsp;<strong>{ this.props.t('Down Payment Due') }</strong></span>
                                    </React.Fragment>
                                : null }
                                { l.propertyTaxDue !== '0' ? <span className="capitalize">; <strong>{ this.props.t('Property Tax Due') }</strong></span> : null }
                            </React.Fragment>
                        }
                        detailsRed={!isZero(l.balanceDue)}
                        details={(
                            <React.Fragment>
                                <SimpleCurrency amount={l.balanceDue} />
                                <Button variant="outline-primary" size="sm" style={{ marginLeft: '25px' }}><AiOutlineInfoCircle /> { this.props.t('View Details') }</Button>
                            </React.Fragment>
                        )}
                    />
                ))}
            />
        );
    }

    get rentals() {
        if (!this.props.data || !Array.isArray(this.props.data.rentals) || this.props.data.rentals.length === 0) {
            return null;
        }

        return (
            <PageBody.List
                title={this.props.t('Rentals')}
                emptyContent={<Alert variant="info">{ this.props.t('You currently have no rentals.') }</Alert>}
                items={this.props.data.rentals.map((r) => (
                    <PageBody.List.Item
                        key={r.id}
                        linksTo={`/rental/${ r.id }`}
                        thumb={<AiFillDollarCircle color={ r.status === 'late' ? '#ff5a5a' : '' } />}
                        title={r.label}
                        description={
                            <React.Fragment>
                                <span className="capitalize">Status: { r.status.replaceAll('-', ' ') }</span>
                            </React.Fragment>
                        }
                        detailsRed={!isZero(r.balanceDue)}
                        details={(
                            <React.Fragment>
                                <SimpleCurrency amount={r.balanceDue} />
                                <Button variant="outline-primary" size="sm" style={{ marginLeft: '25px' }}><AiOutlineInfoCircle /> { this.props.t('View Details') }</Button>
                            </React.Fragment>
                        )}
                    />
                ))}
            />
        );
    }

    get featureCards() {
        if (!this.props.data) {
            return null;
        }

        const propertyTaxDue = this.props.data.loans.reduce((val, loan) => val.add(loan.propertyTaxDue), currency(0, { precision: 4 }));

        return (
            <PageBody.Feature>
                <PageBody.Feature.Card key="nextDueDate" color="red" thumb={<AiOutlineCalendar />} title={this.props.t('Next Due Date')} detail={<SimpleDate date={this.props.data.nextDueDate} simplier />} />
                <PageBody.Feature.Card key="lastPaymentDate" color="blue" thumb={<AiOutlineCalendar />} title={this.props.t('Last Payment Date')} detail={<SimpleDate date={this.props.data.lastPaymentDate} simplier />} />
                { propertyTaxDue.value !== 0 ?
                    <PageBody.Feature.Card key="propertyTaxDue" color="violet" thumb={<TbReceiptTax />} title={this.props.t('Property Tax Due')} detail={<SimpleCurrency amount={propertyTaxDue} />} />
                : null }
            </PageBody.Feature>
        );
    }

    get headerButtons() {
        // when no data or online payments are disabled
        // don't show the only button that's there right now
        if (!this.props.data || this.props.data.org.onlinePaymentsDisabled) {
            return null;
        }

        return ([
            <PageBody.Header.Button
                key="paymentMethods"
                color="blue"
                icon={<AiOutlineBank />}
                text={this.props.t('Payment Methods')}
                tooltip={this.props.t('View and manage the payment methods you have connected.')}
                onClick={this.onViewPaymentMethods}
            />,
        ]);
    }

    render() {
        return (
            <PageBody bodyContentLg loading={!this.props.data}>
                <PageBody.Header
                    leftText={this.props.t('Balance Due')}
                    leftContent={this.props.data ? <SimpleCurrency amount={this.props.data.balanceDue} /> : null}
                    buttons={this.headerButtons}
                />

                { this.updatedAccountInformation }
                { this.verifyEmailAlert }
                { this.anyDownPaymentRequiredAlert }

                { this.featureCards }
                { this.rentals }
                { this.loans }
            </PageBody>
        );
    }
}

export const DashboardView = withTranslation()(connector(withRouter(DashboardBase)));
